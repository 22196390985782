import ApiService from '@/common/api/api.service';

let apiBasePath = 'DocumentoPadrao';

const DocumentoPadraoService = {
  // FUNÇÕES CRUD:
  async criar(form) {
    let { data } = await ApiService.post(`${apiBasePath}`, form);
    return data;
  },
  async editar(form) {
    let { data } = await ApiService.put(`${apiBasePath}`, form);
    return data;
  },
  async excluir(itemId) {
    let { data } = await ApiService.delete(`${apiBasePath}/${itemId}`);
    return data;
  },
  // FUNÇÕES DE BUSCA:
  async obterPorId(itemId) {
    let { data } = await ApiService.get(`${apiBasePath}/${itemId}`);
    return data;
  },
  async obterTodos() {
    let { data } = await ApiService.get(`${apiBasePath}`);
    return data;
  },
  async obterTodosPaginado(paginacao) {
    let { data } = await ApiService.get(`${apiBasePath}/obter-todos-paginado`, {
      params: {
        TamanhoDaPagina: paginacao.tamanhoDaPagina,
        NumeroDaPagina: paginacao.numeroDaPagina,
      },
    });
    return data;
  },
};

export default DocumentoPadraoService;
