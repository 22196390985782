<template>
  <div>
    <b-table
      :fields="fields"
      :items="termos"
      :empty-text="$t('GERAL.NENHUM_REGISTRO')"
      head-variant="light"
      responsive
      striped
      hover
    >
      <template #cell(termo)="data">{{ data.item }}</template>
      <template #cell(acoes)="data">
        <b-button variant="primary" @click="copiarTexto(data.item)">{{
          $t('GERAL.COPIAR')
        }}</b-button>
      </template>
    </b-table>
    <b-toast
      ref="toast"
      auto-hide-delay="50"
      :no-fade="true"
      variant="success"
      :title="$t('GERAL.SUCESSO')"
    >
      {{ $t('GERAL.TEXTO_COPIADO') }}
    </b-toast>
  </div>
</template>

<script>
// aux e helpers

// components

export default {
  name: 'TabelaGuiaDeTermos',
  data() {
    return {
      termos: [
        '@Matricula@',
        '@NomeDoAluno@',
        '@DataNascimento@',
        '@GrupoDeTurma@',
        '@Turma@',
        '@Curso@',
        '@DataMatricula@',
        '@DataPorExtenso@',
        '@Data@',
        '@NomeDoResponsavel@',
        '@EnderecoDoResponsavel@',
        '@CpfDoResponsavel@',
        '@IdentidadeDoResponsavel@',
      ],
      fields: [
        {
          key: 'termo',
          label: this.$t('ACADEMICO.TERMO'),
        },
        {
          key: 'acoes',
          label: this.$t('GERAL.ACOES'),
        },
      ],
    };
  },
  methods: {
    unsecuredCopyToClipboard(text) {
      const textArea = document.createElement('textarea');
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy');
      } catch (err) {
        console.error('Unable to copy to clipboard', err);
      }
      document.body.removeChild(textArea);
    },
    copiarTexto(content) {
      if (window.isSecureContext && navigator.clipboard) {
        navigator.clipboard.writeText(content);
      } else {
        this.unsecuredCopyToClipboard(content);
      }
    },
    // copiarTexto(texto) {
    //   navigator.clipboard.writeText(texto);
    //   this.$refs.toast.show();
    // },
  },
};
</script>
