<template>
  <div>
    <titulo-formulario-documento-padrao
      :exibirGuiaTermos="exibirGuiaTermos"
      @mostrarTermos="mostrarTermos"
    />
    <b-row class="mt-4">
      <b-col sm="4" v-if="exibirGuiaTermos">
        <h6 class="mt-2">{{ $t('ACADEMICO.GUIA_TERMOS') }}</h6>
        <tabela-guia-termos />
      </b-col>
      <b-col sm="9" :md="exibirGuiaTermos ? '8' : '12'">
        <input-text
          ref="nome"
          v-model="form.nome"
          type="text"
          :label="$t('ACADEMICO.NOME')"
          :placeholder="$t('ACADEMICO.NOME')"
          required
        />

        <input-editor-text
          ref="documentoHm=tml"
          :label="$t(`ACADEMICO.DETALHES_DO_MOTIVO`)"
          :editorToolbar="toolbar"
          v-model="form.documentoHtml"
          :value="form.documentoHtml"
          required
        />
        <div class="text-right">
          <b-button variant="primary" @click="salvar">
            {{ $t('GERAL.SALVAR') }}
          </b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// utils & aux
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';
import rotas from '@/common/utils/rotas';

//services
import DocumentoPadraoService from '@/common/services/documento-padrao/documento-padrao.service.js';

//components
import { InputEditorText, InputText } from '@/components/inputs';
import TabelaGuiaTermos from '../components/tabelas/TabelaGuiaTermos';
import TituloFormularioDocumentoPadrao from '@/views/academico/cej/estrutura/documentos-padrao/components/TituloFormularioDocumentoPadrao.vue';

export default {
  nome: 'FormularioDocumentoPadrao',
  components: {
    TituloFormularioDocumentoPadrao,
    InputText,
    InputEditorText,
    TabelaGuiaTermos,
  },

  data() {
    return {
      form: {
        nome: '',
        documentoHtml: '',
      },
      exibirGuiaTermos: false,
      toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
        [{ indent: '+1' }, { indent: '-1' }],
        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' },
        ],
        ['link', 'blockquote'],
        [{ color: [] }, { background: [] }],
        ['image'],
      ],
    };
  },
  mounted() {
    this.pegarDocumentoPadraoPorId();
  },

  methods: {
    // buscar dados
    pegarDocumentoPadraoPorId() {
      if (!this.$route.params.id) return;
      this.$store.dispatch(START_LOADING);
      DocumentoPadraoService.obterPorId(this.$route.params.id)
        .then(({ data }) => {
          this.form = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    //Salvar | editar
    salvar() {
      if (this.$route.params.id) {
        return this.editarDocumento(this.$route.params.id);
      }
      this.criarNovoDocumento();
    },
    criarNovoDocumento() {
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);
      DocumentoPadraoService.criar(this.form)
        .then(({ data }) => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), data.mensagem);
          this.form = {
            nome: '',
            documentoHtml: '',
          };
          rotas.irPara('academico-estrutura-cej-documento-padrao');
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    editarDocumento(id) {
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);
      DocumentoPadraoService.editar({ ...this.form, id })
        .then(({ data }) => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), data.mensagem);
          rotas.irPara('academico-estrutura-cej-documento-padrao');
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },
    // FUNÇÕES MODAL:
    mostrarTermos() {
      this.exibirGuiaTermos = !this.exibirGuiaTermos;
    },
  },
};
</script>

<style scoped>
.bg-gray-100 {
  background: #f5f7f9;
}
</style>
