<template>
  <topbar :titulo="titulo" :subtitulo="descricao" :breadcrumbs="breadcrumbs">
    <template #header>
      <div
        class="d-flex flex-column flex-md-row justify-content-between align-items-md-center"
      >
        <b-button
          class="w-100 mb-md-0 mb-3 mr-md-3 text-nowrap"
          variant="primary"
          @click="mostrarTermos"
        >
          <span v-if="exibirGuiaTermos">
            {{ $t('ACADEMICO.ESCONDER_GUIA_TERMOS') }}
          </span>
          <span v-else>
            {{ $t('ACADEMICO.EXIBIR_GUIA_TERMOS') }}
          </span>
        </b-button>
        <b-button class="w-100" variant="secondary" @click="irPara">
          {{ $t('GERAL.VOLTAR') }}
        </b-button>
      </div>
    </template>
  </topbar>
</template>
<script>
import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';

export default {
  components: { Topbar },
  props: {
    exibirGuiaTermos: { type: Boolean, default: false },
  },
  data() {
    return {
      breadcrumbs: [
        { titulo: this.$t('ACADEMICO_HOME.PAINEL_ACADEMICO') },
        { titulo: this.$t('ACADEMICO.ESTRUTURA_CEJ') },
        { titulo: this.$t('ACADEMICO.DOCUMENTO_PADRAO') },
        {
          titulo: this.$route.params.id
            ? this.$t('ACADEMICO.EDITAR_DOCUMENTO_PADRAO')
            : this.$t('ACADEMICO.NOVO_DOCUMENTO_PADRAO'),
        },
      ],
    };
  },
  computed: {
    titulo() {
      return this.$route.params.id
        ? this.$t('ACADEMICO.EDITAR_DOCUMENTO_PADRAO')
        : this.$t('ACADEMICO.NOVO_DOCUMENTO_PADRAO');
    },
    descricao() {
      return this.$route.params.id
        ? this.$t('ACADEMICO.EDITAR_DOCUMENTO_PADRAO_DESCRICAO')
        : this.$t('ACADEMICO.NOVO_DOCUMENTO_PADRAO_DESCRICAO');
    },
  },
  methods: {
    irPara() {
      this.$router.push({ name: 'academico-estrutura-cej-documento-padrao' });
    },
    mostrarTermos() {
      this.$emit('mostrarTermos');
    },
  },
};
</script>
